import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Meta from '../components/Meta'
import Pagination from '../components/Pagination'
import Base from '../components/Base'
import Preview from '../components/Preview'

const IndexPage = props => {
  const { edges } = props.data.allMdx

  return (
    <Base size="narrowest" pathName={props.location.pathname}>
      <Meta location={props.location} pageTitle="JavaScript blog" />
      {edges.map(post => (
        <Preview key={post.node.id} post={post} />
      ))}
      <Pagination {...props} />
    </Base>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    allMdx: PropTypes.shape({
      edges: PropTypes.array.isRequired
    })
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string
  })
}

export const pageQuery = graphql`
  query($skip: Int, $limit: Int = 10, $sourceName: String = "posts") {
    allMdx(
      filter: { fields: { sourceName: { eq: $sourceName } } }
      sort: { fields: [frontmatter___date], order: DESC }
      skip: $skip
      limit: $limit
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`

export default IndexPage
