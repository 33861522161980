import styled from 'styled-components'

export const PaginationWrap = styled.div({
  margin: '20px 0',
  display: 'flex',
  justifyContent: 'space-between'
})

export const Placeholder = styled.span({
  color: 'var(--gray-light)'
})
