import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { PaginationWrap, Placeholder } from './styles'

const NavLink = props => {
  const url = props.isFirstPage ? '/page/2' : props.url

  if (url) {
    return <Link to={url}>{props.children}</Link>
  }

  return <Placeholder>{props.children}</Placeholder>
}

const Pagination = props => {
  const { nextPath, previousPath, limit } = props.pageContext

  // We don’t have `limit` the data on the first page, so that tells us it’s the
  // first page.
  const isFirstPage = !limit

  return (
    <PaginationWrap>
      <NavLink url={previousPath}>&larr; Previous</NavLink>
      <NavLink url={nextPath} isFirstPage={isFirstPage}>
        Next &rarr;
      </NavLink>
    </PaginationWrap>
  )
}

NavLink.propTypes = {
  isFirstPage: PropTypes.bool,
  url: PropTypes.string,
  children: PropTypes.node
}

Pagination.propTypes = {
  pageContext: PropTypes.shape({
    nextPath: PropTypes.string,
    previousPath: PropTypes.string,
    limit: PropTypes.number
  })
}

export default Pagination
