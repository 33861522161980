import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { Post, PostTitle } from './styles'

const Preview = props => {
  const slashed = props.isDraft ? '/' : ''

  return (
    <Post>
      <PostTitle>
        <Link to={slashed + props.post.node.fields.slug}>
          {props.post.node.frontmatter.title}
        </Link>
      </PostTitle>
      {Boolean(props.post.node.excerpt) && <p>{props.post.node.excerpt}</p>}
    </Post>
  )
}

Preview.propTypes = {
  isDraft: PropTypes.bool,
  post: PropTypes.shape({
    node: PropTypes.shape({
      excerpt: PropTypes.string.isRequired,
      frontmatter: PropTypes.shape({
        title: PropTypes.string.isRequired
      }),
      fields: PropTypes.shape({
        slug: PropTypes.string.isRequired
      })
    })
  })
}

export default Preview
